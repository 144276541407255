"use client"

import { TabWrapper } from "@/components/fusion/tab-wrapper"
import React from "react"
import { PiStarFourFill } from "react-icons/pi"

const KurikulumTerbaru = ({ data }) => {
  return (
    <section className="max-container padding-container flex flex-col lg:items-center py-20 gap-3 ">
      <div className="flex justify-center items-center w-full">
        <div className="w-full h-[1px] border-dashed border-t-2 border-slate-300  border-spacing-10" />
        <PiStarFourFill size={40} className="text-slate-400" />

        <h2 className="lg:text-4xl font-bold w-full text-center">
          Kurikulum
          <span className="text-slate-500"> terbaru</span>
        </h2>
        <PiStarFourFill size={40} className="text-slate-400" />
        <div className="w-full h-[1px] border-dashed border-t-2 border-slate-300  border-spacing-10" />
      </div>
      <p className="text-xs mb-5">
        Mata kuliah unggulan bersama Dosen berpengalaman
      </p>

      <TabWrapper data={data} />
    </section>
  )
}

export default KurikulumTerbaru
