import { SF_Pro } from "@/lib/fonts"
import { cn } from "@/lib/utils"
import React from "react"

export default function CustomIcon({ icon, className, ...props }) {
  return (
    <div
      className={cn(SF_Pro.className, className ? className : "mx-2")}
      {...props}>
      {icon}
    </div>
  )
}
