import { cn } from "@/lib/utils"
import Image from "next/image"
import Link from "next/link"
import React from "react"
import { buttonVariants } from "../ui/button"
import { Card } from "../ui/card"
import { TabsContent } from "../ui/tabs"
import CustomIcon from "./CustomIcon"

const TabItems = ({ kurikulumData }) => {
  return (
    <>
      {kurikulumData.map((item, index) => (
        <TabsContent key={index} value={item.departement} className="w-full">
          <div className="grid lg:grid-cols-3 gap-10 my-5 w-full">
            {item.subjects.map((matakuliah, index) => (
              <Card
                key={index}
                className="rounded-xl w-[350px] h-[180px] bg-gradient-to-tr from-transparent via-transparent to-slate-300/30 overflow-hidden p-5 flex flex-col justify-between hover:shadow-md transition-all duration-300">
                <div className="p-5">
                  <p className="text-2xl font-bold  w-9/12 leading-snug">
                    {matakuliah.name}
                  </p>
                </div>
                <div className="flex justify-end self-end">
                  <Link
                    href={`public-class/${matakuliah.kode}`}
                    className={cn(
                      buttonVariants({ size: "sm" }),
                      "rounded-full",
                    )}>
                    Lihat matkul
                    <CustomIcon icon="􀄕" className={"ml-2"} />
                  </Link>
                </div>
              </Card>
            ))}
          </div>
        </TabsContent>
      ))}
    </>
  )
}

export default TabItems
